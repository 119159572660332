<template>
	<div class="section-11">
		<div :class="[ $mq.below(960) ? 'container-section-mobile' : 'container-section']">
			<div class="content-section">
				<div class="title">Companii care cresc cu TBF Digital</div>
				<div class="list-testimonials">
					<swiper class="swiper" :options="swiperOption">
						<swiper-slide>
							<div class="content-testimonial">
								<div class="data">
									<div class="message">Conducem fără micromanagement peste 1000 de colegi în România, Ungaria și Marea Britanie. Totul datorită TBF Digital și a sistemului de management creat de Răzvan Căzănescu.</div>
									<div class="icon-review"><img src="/build/images/testimonials/quotes.svg"></div>
									<div class="name">Dan Paștiu</div>
									<div class="job">DAbo Doner - Fondator</div>
								</div>
								<div class="image-review">
									<img src="/build/images/testimonials/Dan Pastiu.jpg">
								</div>
							</div>
						</swiper-slide>
						<swiper-slide>
							<div class="content-testimonial">
								<div class="data">
									<div class="message">Îmi conduc afacerile prin sistemul TBF de mai mult de 5 ani. Deși colegii mei sunt în România, acest model de management mi-a permis să locuiesc în Spania și Marea Britanie fără să pierd controlul afacerilor.</div>
									<div class="icon-review"><img src="/build/images/testimonials/quotes.svg"></div>
									<div class="name">Ștefan Irimia</div>
									<div class="job">All About Parenting - CoFondator și CEO</div>
								</div>
								<div class="image-review">
									<img src="/build/images/testimonials/Stefan Irimia.jpg">
								</div>
							</div>
						</swiper-slide>
						<swiper-slide>
							<div class="content-testimonial">
								<div class="data">
									<div class="message">Deși conduc o afacere de câteva milioane de euro, cu peste 100 de colegi, pot să spun cu mâna pe inimă că am devenit antreprenor adevărat doar după ce am folosit sistemul de management TBF. Mulțumesc Răzvan și multumesc TBF Digital.</div>
									<div class="icon-review"><img src="/build/images/testimonials/quotes.svg"></div>
									<div class="name">Ciprian Chelariu</div>
									<div class="job">Izotec - Fondator și CEO</div>
								</div>
								<div class="image-review">
									<img src="/build/images/testimonials/Ciprian Chelariu.jpg">
								</div>
							</div>
						</swiper-slide>
						<swiper-slide>
							<div class="content-testimonial">
								<div class="data">
									<div class="message">Nu am crezut niciodată că managementul poate să fie atât de clar și simplu. Deși am mai puțin de 10 colegi full time, organizarea lor era un calvar. Însă, prin obiective clare și urmând articolele de suport din TBF Digital, mi-am reinventat afacerea… dar și viața personală.</div>
									<div class="icon-review"><img src="/build/images/testimonials/quotes.svg"></div>
									<div class="name">Silviu Dragomir</div>
									<div class="job">Eucom - Fondator și CEO</div>
								</div>
								<div class="image-review">
									<img src="/build/images/testimonials/Silviu Dragomir.jpg">
								</div>
							</div>
						</swiper-slide>
						<swiper-slide>
							<div class="content-testimonial">
								<div class="data">
									<div class="message">Scopul meu a fost să pot să deleg tot ce ține de operațional și să mă focalizez doar pe strategie și produs. Nimic nu a funcționat până când nu am implementat sistemul TBF și TBF Digital. Mulțumesc Răzvan pentru prietenia de peste 10 ani.</div>
									<div class="icon-review"><img src="/build/images/testimonials/quotes.svg"></div>
									<div class="name">Cristi Panaite</div>
									<div class="job">KiloStop - Fondator și CEO</div>
								</div>
								<div class="image-review">
									<img src="/build/images/testimonials/CristianPanaite.jpeg">
								</div>
							</div>
						</swiper-slide>
						<swiper-slide>
							<div class="content-testimonial">
								<div class="data">
									<div class="message">Sistemul TBF mi-a permis, în plină pandemie, să cresc și să dezvolt o companie în industria de construcții. Funcționează la mine și funcționează la alți prieteni antreprenori. Mulțumesc Răzvan și mulțumesc TBF.</div>
									<div class="icon-review"><img src="/build/images/testimonials/quotes.svg"></div>
									<div class="name">Mircea Barticel</div>
									<div class="job">BDM Roof System - Fondator și CEO</div>
								</div>
								<div class="image-review">
									<img src="/build/images/testimonials/Mircea Barticel.jpeg">
								</div>
							</div>
						</swiper-slide>
					</swiper>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

	export default {
		components: {
			Swiper,
			SwiperSlide
		},
		data() {
			return {
				swiperOption: {
					slidesPerView: 'auto',
					centeredSlides: this.$mq.below(960) ? true : false,
					roundLengths: true,
					spaceBetween: this.$mq.below(960) ? (this.$mq.below(640) ? 20 : 50) : 70,
					loop: true,
					slideToClickedSlide: true
				}
			}
		}
	}
</script>
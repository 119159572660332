<template>
	<svg width="1069" height="261" viewBox="0 0 1069 261" fill="none" xmlns="http://www.w3.org/2000/svg" class="svg-section-13">
		<path class="path-hidden path-hidden-13 square-1-sect-13" fill-rule="evenodd" clip-rule="evenodd" d="M501 47.33c-4.45 0-6.08-.43-7.72-1.3a8.75 8.75 0 01-3.64-3.64c-.87-1.64-1.3-3.27-1.3-7.73V13.34c0-4.46.43-6.1 1.3-7.73a8.75 8.75 0 013.64-3.63c1.64-.88 3.27-1.31 7.73-1.31h21.32c4.46 0 6.09.43 7.72 1.3a8.74 8.74 0 013.64 3.64c.88 1.64 1.31 3.27 1.31 7.73v21.32c0 4.46-.43 6.1-1.3 7.73a8.74 8.74 0 01-3.65 3.64c-1.63.87-3.26 1.3-7.72 1.3H501z" fill="#fff" stroke="#DCDEE1" stroke-width="1.17"/>
		<path class="path-hidden path-hidden-13 line-1-sect-13" d="M1054.75 222.33v-66.5a23.33 23.33 0 00-23.33-23.33H533.83a23.33 23.33 0 01-23.33-23.33V47.92" stroke="#DCDEE1" stroke-width="1.17" stroke-linecap="square"/>
		<path class="path-hidden path-hidden-13 square-2-sect-13" fill-rule="evenodd" clip-rule="evenodd" d="M1042.5 229.3c0-2.45.24-3.35.72-4.25a4.82 4.82 0 012-2c.9-.48 1.8-.72 4.25-.72h11.73c2.45 0 3.35.24 4.25.72a4.82 4.82 0 012 2c.48.9.72 1.8.72 4.25v11.73c0 2.45-.24 3.35-.72 4.25a4.81 4.81 0 01-2 2c-.9.48-1.8.72-4.25.72h-11.73c-2.45 0-3.35-.24-4.25-.72a4.81 4.81 0 01-2-2c-.48-.9-.72-1.8-.72-4.25V229.3z" fill="#0881FF" fill-opacity=".6" stroke="#0060C3" stroke-width="1.17"/>
		<path class="path-hidden path-hidden-13 square-3-sect-13" fill-rule="evenodd" clip-rule="evenodd" d="M7.64 260.83c-2.46 0-3.35-.23-4.25-.72a4.8 4.8 0 01-2-2c-.48-.9-.72-1.8-.72-4.25v-11.72c0-2.46.24-3.35.72-4.25a4.81 4.81 0 012-2c.9-.48 1.8-.72 4.25-.72h11.72c2.46 0 3.35.24 4.25.72a4.81 4.81 0 012 2c.48.9.72 1.8.72 4.25v11.72c0 2.46-.24 3.35-.72 4.25a4.8 4.8 0 01-2 2c-.9.49-1.8.72-4.25.72H7.64z" fill="#F1F3F6"/>
	</svg>
</template>

<script>
	import { gsap } from "gsap";

	export default {
		mounted() {
			gsap.set(".path-hidden-13", {visibility:"visible"})
			var tl7 = gsap.timeline({
				scrollTrigger: {
					trigger: '.svg-section-13',
					start: "top center",
					end: "center center"
				}
			})

			tl7.from('.square-1-sect-13', {duration: 0.3, opacity: 0})
			tl7.from('.line-1-sect-13', {duration: 0.5, drawSVG: "99.99% 100%"})
			tl7.from('.square-2-sect-13', {duration: 0.3, opacity: 0})
			tl7.from('.square-3-sect-13', {duration: 0.3, opacity: 0})
		},
		methods: {
			
		}
	}
</script>
<template>
	<div class="section-4 sect-mobile">
		<div class="container-section-mobile">
			<div class="org-section">
				<div class="full-org-image">
					<svg-section-4 />
				</div>
				<div class="btn-view-video">
					<button>
						Planifică vizual cu TBF Digital
						<icon-arrow class="icon-arrow" />
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import SvgSection4 from '../../assets/landing/SvgVideoOrg'
	import IconArrow from '../../assets/landing/Arrow'
	import { gsap } from "gsap";
	import { ScrollTrigger } from "gsap/ScrollTrigger";
	import { ExpoScaleEase } from "gsap/EasePack";

	export default {
		components: {
			IconArrow,
			SvgSection4
		},
		mounted(){
		},
		methods: {
			
		}
	}
</script>
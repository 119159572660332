<template>
	<div class="section-7">
		<div class="container-section">
			<div class="top-content">
				<svg-section-7 />
				<div class="squares">
					<img id="squaresSect7" src="/build/images/squares-1-sect-7.png">
				</div>
			</div>

			<div class="long-center-text">
				<div class="paragraph paragraph-1 active" data-pId="paragraph-1">Promisiunile zilnice păstrează focusul oamenilor pe ceea ce este important.</div><div class="paragraph paragraph-2" data-pId="paragraph-2"> Fiecare coleg este obligat să își stabilească o promisiune zilnică în TBF Digital.</div><div class="paragraph paragraph-3" data-pId="paragraph-3"> Promisiunea zilnică răspunde la întrebarea:</div><div class="paragraph paragraph-4" data-pId="paragraph-4"> Care este cel mai important lucru pe care îl vei realiza azi?</div><div class="paragraph paragraph-5" data-pId="paragraph-5"> Fiecare promisiune trebuie să contribuie la un obiectiv de care persoana este responsabilă.</div><div class="paragraph paragraph-6" data-pId="paragraph-6"> Vei putea să vezi performanța și focusul fiecărui coleg printr-un singur click pe profilul acestuia.</div>
				<div class="small-link">
					<router-link to="/tbf-digital/abonamente" target="_blank">Crește performanța prin puterea promisiunilor zilnice <icon-arrow class="icon-arrow right"/></router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import IconArrow from '../../assets/landing/Arrow'
	import SvgSection7 from '../../assets/landing/SvgSection7'
	import { gsap } from "gsap";
	import { ScrollTrigger } from "gsap/ScrollTrigger";

	export default {
		components:{
			SvgSection7,
			IconArrow
		},
		mounted() {
			gsap.from("#squaresSect7", {
				scrollTrigger: {
					trigger: '#squaresSect7',
					start: "top center",
					end: "bottom center"
				},
				opacity: 0
			})

			let paragraphs = gsap.utils.toArray(".paragraph")

			paragraphs.forEach((paragraph, i) => {
				ScrollTrigger.create({
					trigger: paragraph,
					start: "top center",
					end: "bottom center",
					id: 'paragraph-7-' + i,
					onUpdate: self => this.checkHasActive(self.isActive, paragraph),
					onLeaveBack: () => this.checkFirstChild(paragraph),
					onLeave: () => this.checkLastChild(paragraph)
				})
			})
		},
		methods: {
			checkHasActive(active, paragraph){
				if(active){
					var paragraphs_active = document.querySelectorAll('.paragraph.active')

					if(paragraphs_active.length > 0){
						paragraphs_active.forEach((paragraphActive) => {
							if(paragraphActive.getAttribute('data-pId') != paragraph.getAttribute('data-pId')){
								paragraphActive.classList.remove('active')
							}
						})
					}
					paragraph.classList.add('active')
				}else{
					paragraph.classList.remove('active')
				}
			},
			checkFirstChild(paragraph){
				if(paragraph.getAttribute('data-pId') == 'paragraph-1'){
					paragraph.classList.add('active')
				}
			},
			checkLastChild(paragraph){
				if(paragraph.getAttribute('data-pId') == 'paragraph-4'){
					paragraph.classList.add('active')
				}
			}
		}
	}
</script>
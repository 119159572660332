<template>
	<div class="section-2 sect-mobile">
		<div class="container-section-mobile">
			<div class="text-animated">
				<div id="textSection2" class="strong">Control</div>
				<div class="fixed-text">fără Micromanagement</div>
			</div>
			<div class="desc-mobile">
				TBF Digital este bazat pe un sistem complet de management, prin obiective și responsabilități clare, care îți permite să ai control și să scalezi fără să faci micromanagement.
			</div>
		</div>

		<img id="circleBlueSect1" class="circle-blue" src="/build/images/circle-sect-2.svg">
		<img id="rectYellowSect1" class="rect-yellow" src="/build/images/rect-sect-2.svg">
	</div>
</template>

<script>
	import { gsap } from "gsap";

	export default {
		mounted() {
			gsap.from("#circleBlueSect1", {
				scrollTrigger: {
					trigger: '#circleBlueSect1',
					start: "top center",
				},
				opacity: 0
			})

			gsap.from("#rectYellowSect1", {
				scrollTrigger: {
					trigger: '#rectYellowSect1',
					start: "top center"
				},
				opacity: 0
			})

			gsap.timeline().repeat(-1).repeatDelay(1)
			.to({}, {duration:2})
			.to('#textSection2', {duration: 1, text: { value: "Rezultate"}})
			.to({}, {duration:2})
			.to('#textSection2', {duration: 1, text: { value: "Scalare"}})
			.to({}, {duration:2})
			.to('#textSection2', {duration: 1, text: { value: "Responsabilitate"}})
			.to({}, {duration:2})
			.to('#textSection2', {duration: 1, text: { value: "Control"}})
		},
		methods: {
			
		}
	}
</script>
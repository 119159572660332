<template>
	<div class="section-13">
		<div :class="[ $mq.below(960) ? 'container-section-mobile' : 'container-section']">
			<div class="top-content" v-if="$resize && $mq.above(640)">
				<svg-section-13 />
			</div>

			<div class="long-center-text">
				<div class="paragraph-sect-13 paragraph-1 active" data-pId="paragraph-13-1">O cultură în care oamenii rămân decenii.</div><div class="paragraph-sect-13 paragraph-2" data-pId="paragraph-13-2"> Claritatea oferită de obiective și de rezultate cheie menține oamenii extraordinari pe termen lung.</div><div class="paragraph-sect-13 paragraph-3" data-pId="paragraph-13-3"> Măsurarea continuă împinge oamenii buni să se autodepășească și să crească.</div><div class="paragraph-sect-13 paragraph-4" data-pId="paragraph-13-4"> Acest sistem oferă controlul oamenilor pe propria lor performanță și siguranța unei evaluări obiective.</div>
				<div class="small-link">
					<router-link to="/tbf-digital/abonamente" target="_blank">Creează o cultură de responsabilitate și performanță <icon-arrow class="icon-arrow right"/></router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import IconArrow from '../../assets/landing/Arrow'
	import SvgSection13 from '../../assets/landing/SvgSection13'
	import { gsap } from "gsap";
	import { ScrollTrigger } from "gsap/ScrollTrigger";

	export default {
		components:{
			SvgSection13,
			IconArrow
		},
		mounted() {
			let paragraphs = gsap.utils.toArray(".paragraph-sect-13")

			paragraphs.forEach((paragraph, i) => {
				ScrollTrigger.create({
					trigger: paragraph,
					start: "top center",
					end: "bottom center",
					id: 'paragraph-13-' + i,
					onUpdate: self1 => this.checkHasActive(self1.isActive, paragraph),
					onLeaveBack: () => this.checkFirstChild(paragraph),
					onLeave: () => this.checkLastChild(paragraph)
				})
			})
		},
		methods: {
			checkHasActive(active, paragraph){
				if(active){
					var paragraphs_active = document.querySelectorAll('.paragraph-sect-13.active')

					if(paragraphs_active.length > 0){
						paragraphs_active.forEach((paragraphActive) => {
							if(paragraphActive.getAttribute('data-pId') != paragraph.getAttribute('data-pId')){
								paragraphActive.classList.remove('active')
							}
						})
					}
					paragraph.classList.add('active')
				}else{
					paragraph.classList.remove('active')
				}
			},
			checkFirstChild(paragraph){
				if(paragraph.getAttribute('data-pId') == 'paragraph-13-1'){
					paragraph.classList.add('active')
				}
			},
			checkLastChild(paragraph){
				if(paragraph.getAttribute('data-pId') == 'paragraph-13-4'){
					paragraph.classList.add('active')
				}
			}
		}
	}
</script>
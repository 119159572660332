<template>
	<div class="section-5 sect-mobile">
		<div class="container-section-mobile">
			<div class="svg-animation-1-mobile">
				<svg-1-section-5 class="svg-1-section-5"/>
				<svg-2-section-5 class="svg-2-section-5" />
			</div>
			<div class="text-animated">
				<div class="fixed-text">Funcționează remarcabil cu</div>
				<div id="numberChanged" class="strong">{{ startNumber }} colegi</div>
			</div>
			<div class="desc">Sistemul de management prin obiective funcționează remarcabil, de la nivelul de startup până la cel de multinațională cu mii de colegi și zeci de locații.</div>
			<div class="svg-animation-2-mobile"><svg-3-section-5 /></div>
		</div>
	</div>
</template>

<script>
	import Svg1Section5 from '../../assets/landing/mobile/Svg01Section05'
	import Svg2Section5 from '../../assets/landing/mobile/Svg02Section05'
	import Svg3Section5 from '../../assets/landing/mobile/Svg03Section05'
	import { gsap } from "gsap";
	import { ScrollTrigger } from "gsap/ScrollTrigger";

	export default {
		data() {
			return {
				startNumber: 6,
			};
		},
		components:{
			Svg1Section5,
			Svg2Section5,
			Svg3Section5
		},
		mounted() {
			gsap.set(".path-hidden-5-3-mob", {visibility:"visible"})

			var startCount = 6, num = {var:startCount};
			gsap.timeline({
				scrollTrigger: {
					trigger: '.section-5',
					pin: true,  
					start: "top top",
					end: "+=100% top",
					scrub: true
				}
			})
			.to(num, {var: 20, duration: 1, ease:"none", onUpdate:() => this.changeNumber(num)})
			.add(this.animateUsersCount(), "-=1")
			.to({}, {duration:2})

			.to(num, {var: 100, duration: 1, ease:"none", onUpdate:() => this.changeNumber(num)})
			.add(this.animateUsersCount2(), "-=1")
			.to({}, {duration:2})

			.to(num, {var: 1000, duration: 1, ease:"none", onUpdate:() => this.changeNumber(num)})
			.add(this.animateUsersCount3(), "-=1")
			.to({}, {duration:2})
		},
		methods: {
			changeNumber(num){
				this.startNumber = (num.var).toFixed()
			},
			animateUsersCount(){
				gsap.set(".path-hidden-5-2-mob", {visibility:"visible"})

				var tl = gsap.timeline()
				tl.from('.user-1-sect-5-b-mob', {duration: 0.5, opacity: 0})
				tl.from('.line-1-sect-5-b-mob', {duration: 0.5, drawSVG: '99.99% 100%'})
				tl.from('.user-2-sect-5-b-mob', {duration: 0.5, opacity: 0})
				return tl
			},
			animateUsersCount2(){
				gsap.set(".path-hidden-5-3-mob", {visibility:"visible"})
				var tl2 = gsap.timeline()
				tl2.from('.user-1-sect-5-c-mob', {duration: 0.5, opacity: 0})
				tl2.from('.line-1-sect-5-c-mob', {duration: 0.5, drawSVG: '99.99% 100%'})
				tl2.from('.user-2-sect-5-c-mob', {duration: 0.5, opacity: 0})
				return tl2
			},
			animateUsersCount3(){
				gsap.set(".path-hidden-5-4-mob", {visibility:"visible"})
				var tl3 = gsap.timeline()
				tl3.from('.user-1-sect-5-d-mob', {opacity: 0})
				return tl3
			}
		}
	}
</script>
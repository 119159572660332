<template>
	<svg class="svg-section-14-a" width="571" height="255" viewBox="0 0 571 255" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path class="path-hidden path-hidden-14-a square-1-sect-14-a" fill-rule="evenodd" clip-rule="evenodd" d="M34.83 95.17c4.46 0 6.09.43 7.72 1.3a8.75 8.75 0 013.64 3.64c.88 1.64 1.31 3.27 1.31 7.73v21.32c0 4.46-.43 6.1-1.3 7.73a8.75 8.75 0 01-3.65 3.63c-1.63.88-3.26 1.31-7.72 1.31H13.5c-4.46 0-6.1-.43-7.73-1.3a8.75 8.75 0 01-3.64-3.64c-.87-1.64-1.3-3.27-1.3-7.73v-21.32c0-4.46.43-6.1 1.3-7.73a8.75 8.75 0 013.64-3.64c1.64-.87 3.27-1.3 7.73-1.3h21.32z" fill="#fff" stroke="#DCDEE1" stroke-width="1.17"/>
		<path class="path-hidden path-hidden-14-a square-2-sect-14-a" fill-rule="evenodd" clip-rule="evenodd" d="M566.05 133.67c1.45 0 1.98.14 2.5.42.52.27.92.67 1.2 1.18.28.53.42 1.06.42 2.51v6.93c0 1.45-.14 1.98-.43 2.51-.27.52-.67.91-1.18 1.19-.53.28-1.06.42-2.51.42h-6.93c-1.45 0-1.98-.14-2.51-.42a2.84 2.84 0 01-1.19-1.19c-.28-.53-.42-1.06-.42-2.5v-6.94c0-1.44.14-1.97.42-2.5.28-.52.68-.92 1.19-1.19.53-.28 1.06-.42 2.5-.42h6.94z" fill="#DCDEE1"/>
		<path class="path-hidden path-hidden-14-a line-1-sect-14-a" d="M562.58 144.17v86.91a23.33 23.33 0 01-23.33 23.34H177.58a23.33 23.33 0 01-23.33-23.34V24A23.33 23.33 0 00130.92.67H47.5A23.33 23.33 0 0024.17 24v71.17" stroke="#DCDEE1" stroke-width="1.17" stroke-linecap="square"/>
	</svg>
</template>

<script>
	import { gsap } from "gsap";

	export default {
		mounted() {
			gsap.set(".path-hidden-14-a", {visibility:"visible"})

			gsap.timeline({
				scrollTrigger: {
					trigger: '.svg-section-14-a',
					start: "top center",
					end: "center center"
				}
			})
			.from('.square-1-sect-14-a', 0.5, {opacity:0})
			.from('.line-1-sect-14-a', 1, {drawSVG: "99.99% 100%"})
			.from('.square-2-sect-14-a', 1, {opacity:0})
		}
	}
</script>
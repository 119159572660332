<template>
	<div class="section-18">
		<div :class="[ $mq.below(960) ? 'container-section-mobile' : 'container-section']">
			<div class="content-section">
				<div class="title">Funcționalități TBF Digital</div>
				<div class="list-features">
					<swiper class="swiper" :options="swiperOption">
						<swiper-slide>
							<div class="content-feature">
								<div class="icon-feature"><icon-objectives /></div>
								<div class="name">Obiective</div>
								<div class="description">De la obiective pleacă totul în TBF Digital. Un obiectiv are un set de rezultate cheie măsurabile, un responsabil și o perioadă de desfășurare. Obiectivele pot fi grupate pentru a creea o hartă vizuală a strategiei.</div>
							</div>
						</swiper-slide>
						<swiper-slide>
							<div class="content-feature">
								<div class="icon-feature"><icon-krs /></div>
								<div class="name">Rezultate cheie</div>
								<div class="description">Un obiectiv este o colecție de rezultate cheie care trebuie realizate pentru a atinge obiectivul. Fiecare rezultat cheie are un responsabil, o modalitate de măsurare și o țintă care trebuie atinsă.</div>
							</div>
						</swiper-slide>
						<swiper-slide>
							<div class="content-feature">
								<div class="icon-feature"><icon-groups /></div>
								<div class="name">Grupuri</div>
								<div class="description">Grupurile și subgrupurile îți permit să organizezi obiectivele pe departamente sau să le categorisești așa cum ai nevoie. Acest lucru îți oferă posibilitatea să vizualizezi strategia companiei ca pe o hartă.</div>
							</div>
						</swiper-slide>
						<swiper-slide>
							<div class="content-feature">
								<div class="icon-feature"><icon-tasks /></div>
								<div class="name">Checklist</div>
								<div class="description">Fiecare rezultat cheie are atașat un checklist. O listă de taskuri sau de rezultate mai mici care trebuie bifate pe parcurs. Acest lucru îți permite să delegi complet și ajută oamenii tăi să nu uite lucruri importante.</div>
							</div>
						</swiper-slide>
						<swiper-slide>
							<div class="content-feature">
								<div class="icon-feature"><icon-promises /></div>
								<div class="name">Promisiuni zilnice</div>
								<div class="description">Fiecare coleg este obligat să își stabilească o promisiune la începutul fiecărei zi lucrătoare. Promisiunea zilei răspunde la întrebarea: Care este cel mai important lucru pe care îl voi realiza azi?</div>
							</div>
						</swiper-slide>
						<swiper-slide>
							<div class="content-feature">
								<div class="icon-feature"><icon-progress /></div>
								<div class="name">Măsurarea</div>
								<div class="description">Pentru fiecare rezultat cheie vei putea să stabilești o frecvență de actualizare a progresului de 7, 14, 30 sau 90 de zile. Acest lucru îți permite să rămâi în control în timp ce oamenii tăi stau focalizați pe ceea ce este important.</div>
							</div>
						</swiper-slide>
						<swiper-slide>
							<div class="content-feature">
								<div class="icon-feature"><icon-reviews /></div>
								<div class="name">Evaluarea oamenilor</div>
								<div class="description">Pentru a evalua performanța oamenilor tăi, tot ce ai de făcut este să verifici progresul făcut pe obiectivele și rezultatele cheie de care sunt responsabili, iar apoi să analizezi promisiunile zilnice stabilite.</div>
							</div>
						</swiper-slide>
						<swiper-slide>
							<div class="content-feature">
								<div class="icon-feature"><icon-notifications /></div>
								<div class="name">Notificări</div>
								<div class="description">Toată lumea este ținută la curent prin notificări pe email și direct în soft. Nu vei avea parte de scuze că nu s-a știut că trebuia actualizat progresul la un rezultat sau că s-a uitat să se evalueze o promisiune zilnică.</div>
							</div>
						</swiper-slide>
						<swiper-slide>
							<div class="content-feature">
								<div class="icon-feature"><icon-planning /></div>
								<div class="name">Planificare vizuală</div>
								<div class="description">În TBF Digital poți creea, pentru fiecare trimestru, o hartă vizuală de grupuri, obiective și rezultate cheie. Mai mult decât atât, într-unul din articolele de suport îți arăt cum să îți stabilești viziunea clară sub formă de hartă.</div>
							</div>
						</swiper-slide>
						<swiper-slide>
							<div class="content-feature">
								<div class="icon-feature"><icon-performance /></div>
								<div class="name">Comentarii, felicitări</div>
								<div class="description">Vrei să feliciți o persoană pentru progresul făcut la un rezultat cheie? Vrei să îi atragi atenția sau să aduci o informație nouă? Funcționalitatea de mesaje îți permite să faci acest lucru direct în soft.</div>
							</div>
						</swiper-slide>
					</swiper>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import IconObjectives from '../../assets/landing/features/IconObjectives'
	import IconKrs from '../../assets/landing/features/IconKrs'
	import IconGroups from '../../assets/landing/features/IconGroups'
	import IconTasks from '../../assets/landing/features/IconTasks'
	import IconPromises from '../../assets/landing/features/IconPromises'
	import IconPerformance from '../../assets/landing/features/IconPerformance'
	import IconPlanning from '../../assets/landing/features/IconPlanning'
	import IconProgress from '../../assets/landing/features/IconProgress'
	import IconNotifications from '../../assets/landing/features/IconNotifications'
	import IconReviews from '../../assets/landing/features/IconReviews'

	export default {
		components: {
			IconObjectives,
			IconKrs,
			IconGroups,
			IconTasks,
			IconPromises,
			IconPerformance,
			IconPlanning,
			IconProgress,
			IconNotifications,
			IconReviews
		},
		data() {
			return {
				swiperOption: {
					// setWrapperSize: true,
					slidesPerView: 'auto',
					// autoHeight: true,
					centeredSlides: this.$mq.below(960) ? true : false,
					roundLengths: true,
					loop: this.$mq.below(960) ? true : false,
					// grabCursor: true,
					spaceBetween: this.$mq.below(960) ? (this.$mq.below(640) ? 20 : 50) : 70
				}
			}
		}
	}
</script>
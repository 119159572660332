<template>
	<div class="section-14">
		<img id="square3Sect14" class="square-3-absolute" src="/build/images/square-3-sect-14.svg" v-if="$resize && $mq.above(960)">
		<div :class="[ $mq.below(959) ? 'container-section-mobile' : 'container-section']">
			<div class="top-svg">
				<svg-section-14a v-if="$resize && $mq.above(960)"/>
				<img id="square1Sect14" src="/build/images/square-absolute-1-sect-14.svg">
			</div>

			<div class="content-section content-section-14">
				<div class="title">
					<div class="title-1-sect-14 active">Perfect <br v-if="$resize && $mq.above(960)"/>pentru echipe remote,</div>
					<div class="title-2-sect-14">Remarcabil pentru munca la birou</div>
				</div>
				<div class="description">Pentru că performanța oamenilor se măsoară prin obiective și rezultate cheie, managementul remote al oamenilor devine foarte simplu și usor. Nu mai plătești pentru timpul pe care îl petrec la birou ci pentru rezultate clare și măsurabile. Acest lucru îți permite să angajezi oameni din întreaga țară (sau chiar internațional).</div>
			</div>

			<div class="bottom-svg">
				<svg-section-14b v-if="$resize && $mq.above(960)"/>
				<img id="square2Sect14" src="/build/images/square-absolute-2-sect-14.svg">
			</div>
		</div>
	</div>
</template>

<script>
	import SvgSection14a from '../../assets/landing/SvgSection14'
	import SvgSection14b from '../../assets/landing/SvgSection14b'
	import { gsap } from "gsap";
	import { ScrollTrigger } from "gsap/ScrollTrigger";

	export default {
		components: {
			SvgSection14a,
			SvgSection14b
		},
		mounted() {
			gsap.from("#square1Sect14", {
				scrollTrigger: {
					trigger: '#square1Sect14',
					start: "top center",
					end: "bottom center"
				},
				opacity: 0
			})

			gsap.from("#square2Sect14", {
				scrollTrigger: {
					trigger: '#square2Sect14',
					start: "top center",
					end: "bottom center"
				},
				opacity: 0
			})

			if(this.$resize && this.$mq.above(960)){
				gsap.from("#square3Sect14", {
					scrollTrigger: {
						trigger: '#square3Sect14',
						start: "top center",
						end: "bottom center"
					},
					opacity: 0
				})
			}

			var tl14 = gsap.timeline()
			ScrollTrigger.create({
				animation: tl14,
				trigger: '.content-section-14',
				start: "top center",
				end: "bottom center",
				id: 'scrollTrigger14',
				scrub: true
			})

			tl14.to('.title-1-sect-14', {className: "active"})
			tl14.to('.title-1-sect-14', 0, {className: ""})

			tl14.to('.title-2-sect-14', {className: "active"})
			// tl14.to('.title-2-sect-14', 0,{className: ""})
		}
	}
</script>
<template>
	<div class="section-10">
		<div class="content-section-10">
			<img id="roFlagAnimation" src="/build/images/flag-ro4.jpg">
			<div id="titleFlag" class="title">Devino<br/>Antreprenor TBF</div>
			<button @click="openPricing" class="learn-more">Alătură-te comunității noastre <icon-arrow class="icon-arrow"/></button>
		</div>
	</div>
</template>

<script>
	import IconArrow from '../../assets/landing/Arrow'
	import { gsap } from "gsap";
	import { ScrollTrigger } from "gsap/ScrollTrigger";

	export default {
		components: {
			IconArrow
		},
		mounted() {
			var tl9 = gsap.timeline()
			ScrollTrigger.create({
				animation: tl9,
				trigger: '.content-section-10',
				start: "top top",
				end: "+=50%",
				scrub: true,
				pin: true
			})

			tl9.to('#roFlagAnimation', {xPercent: -100, left: '100%'})
		},
		methods:{
			openPricing(){
				// window.open(this.$router.resolve({name: 'pricing'}).href, '_blank')
				this.$router.push({name: 'pricing'})
			}
		}
	}
</script>
<template>
	<svg width="353" height="245" viewBox="0 0 353 245" fill="none" xmlns="http://www.w3.org/2000/svg" class="svg-section-7">
		<path class="path-hidden path-hidden-7 square-1-sect-7" fill-rule="evenodd" clip-rule="evenodd" d="M34.83 1.5c4.46 0 6.09.43 7.72 1.3a8.75 8.75 0 013.64 3.65c.88 1.63 1.31 3.26 1.31 7.72V35.5c0 4.46-.43 6.1-1.3 7.73a8.75 8.75 0 01-3.65 3.64c-1.63.87-3.26 1.3-7.72 1.3H13.5c-4.46 0-6.1-.43-7.73-1.3a8.75 8.75 0 01-3.64-3.64c-.87-1.64-1.3-3.27-1.3-7.73V14.17c0-4.46.43-6.09 1.3-7.72A8.75 8.75 0 015.78 2.8c1.64-.88 3.27-1.31 7.73-1.31h21.32z" fill="#fff" stroke="#DCDEE1" stroke-width="1.17"/>
		<path class="path-hidden path-hidden-7 square-2-sect-7" fill-rule="evenodd" clip-rule="evenodd" d="M352 154.95c0 2.45-.24 3.35-.72 4.25a4.8 4.8 0 01-2 2c-.9.48-1.8.72-4.25.72H333.3c-2.45 0-3.35-.24-4.25-.72a4.81 4.81 0 01-2-2c-.48-.9-.72-1.8-.72-4.25v-11.73c0-2.45.24-3.35.72-4.25a4.8 4.8 0 012-2c.9-.48 1.8-.72 4.25-.72h11.73c2.45 0 3.35.24 4.25.72a4.8 4.8 0 012 2c.48.9.72 1.8.72 4.25v11.73z" fill="#0881FF" fill-opacity=".6" stroke="#0060C3" stroke-width="1.17"/>
		<path class="path-hidden path-hidden-7 line-1-sect-7" d="M47.5 24.83H149a23.33 23.33 0 0123.33 23.34v172.08a23.33 23.33 0 0023.34 23.33h120.16a23.33 23.33 0 0023.34-23.33v-56.59" stroke="#DCDEE1" stroke-width="1.17" stroke-linecap="square"/>
	</svg>
</template>

<script>
	import { gsap } from "gsap";

	export default {
		mounted() {
			gsap.set(".path-hidden-7", {visibility:"visible"})
			var tl7 = gsap.timeline({
				scrollTrigger: {
					trigger: '.svg-section-7',
					start: "top center",
					end: "center center"
				}
			})

			tl7.from('.square-1-sect-7', {duration: 0.3, opacity: 0})
			tl7.from('.line-1-sect-7', {duration: 0.5, drawSVG: "0%"})
			tl7.from('.square-2-sect-7', {duration: 0.3, opacity: 0})
		},
		methods: {
			
		}
	}
</script>
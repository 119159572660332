<template>
	<svg class="svg-section-14-b" width="738" height="348" viewBox="0 0 738 348" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path class="path-hidden path-hidden-14-b square-2-sect-14-b" fill-rule="evenodd" clip-rule="evenodd" d="M35.5 300.67c4.45 0 6.08.43 7.72 1.3a8.75 8.75 0 013.64 3.64c.87 1.64 1.3 3.27 1.3 7.73v21.32c0 4.46-.43 6.1-1.3 7.73a8.75 8.75 0 01-3.64 3.63c-1.64.88-3.27 1.31-7.73 1.31H14.17c-4.46 0-6.09-.43-7.72-1.3a8.75 8.75 0 01-3.64-3.64c-.88-1.64-1.31-3.27-1.31-7.73v-21.32c0-4.46.43-6.1 1.3-7.73a8.75 8.75 0 013.65-3.63c1.63-.88 3.26-1.31 7.72-1.31H35.5z" fill="#fff" stroke="#DCDEE1" stroke-width="1.17"/>
		<path class="path-hidden path-hidden-14-b square-1-sect-14-b" fill-rule="evenodd" clip-rule="evenodd" d="M731.96.83c2.01 0 2.75.2 3.48.6.7.37 1.26.92 1.64 1.63.4.74.59 1.47.59 3.48v9.59c0 2-.2 2.74-.6 3.48-.37.7-.92 1.26-1.63 1.64-.74.39-1.47.58-3.48.58h-9.59c-2 0-2.74-.2-3.48-.58a3.93 3.93 0 01-1.63-1.64c-.4-.74-.6-1.47-.6-3.48v-9.6c0-2 .2-2.73.6-3.47.37-.7.93-1.26 1.63-1.64.74-.4 1.47-.59 3.48-.59h9.6z" fill="#DCDEE1"/>
		<path class="path-hidden path-hidden-14-b line-1-sect-14-b" d="M718.42 10.75h-374.5a23.33 23.33 0 00-23.34 23.33v266.59A23.33 23.33 0 01297.25 324H48.75" stroke="#DCDEE1" stroke-width="1.17" stroke-linecap="square"/>
	</svg>
</template>


<script>
	import { gsap } from "gsap";

	export default {
		mounted() {
			gsap.set(".path-hidden-14-b", {visibility:"visible"})

			gsap.timeline({
				scrollTrigger: {
					trigger: '.svg-section-14-b',
					start: "top center",
					end: "center center"
				}
			})
			.from('.square-1-sect-14-b', 0.5, {opacity:0})
			.from('.line-1-sect-14-b', 1, {drawSVG: 0})
			.from('.square-2-sect-14-b', 1, {opacity:0})
		}
	}
</script>
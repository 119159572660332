<template>
	<div class="mobile">
		<section-01-mobile />
		<section-02-mobile />
		<section-04-mobile />
		<section-05-mobile />
		<section-06 />
		<section-07 />
		<section-08-mobile />
		<section-09-mobile />
		<section-10 />
		<section-11 />
		<section-12-mobile />
		<section-13 />
		<section-14 />
		<section-15-mobile />
		<section-16-mobile />
		<section-17 />
		<section-18 />
		<footer-tbf />
	</div>
</template>

<script>
	import Section01Mobile from '../LandingMobile/Section01'
	import Section02Mobile from '../LandingMobile/Section02'
	import Section04Mobile from '../LandingMobile/Section04'
	import Section05Mobile from '../LandingMobile/Section05'
	import Section06 from '../LandingDesktop/Section06'
	import Section07 from '../LandingDesktop/Section07'
	import Section08Mobile from '../LandingMobile/Section08'
	import Section09Mobile from '../LandingMobile/Section09'
	import Section10 from '../LandingDesktop/Section10'
	import Section11 from '../LandingDesktop/Section11'
	import Section12Mobile from '../LandingMobile/Section12'
	import Section13 from '../LandingDesktop/Section13'
	import Section14 from '../LandingDesktop/Section14'
	import Section15Mobile from '../LandingMobile/Section15'
	import Section16Mobile from '../LandingMobile/Section16'
	import Section17 from '../LandingDesktop/Section17'
	import Section18 from '../LandingDesktop/Section18'
	import FooterTbf from '../LandingMobile/FooterTbf'

	export default {
		data() {
			return {
			};
		},
		components: {
			Section01Mobile,
			Section02Mobile,
			Section04Mobile,
			Section05Mobile,
			Section06,
			Section07,
			Section08Mobile,
			Section09Mobile,
			Section10,
			Section11,
			Section12Mobile,
			Section13,
			Section14,
			Section15Mobile,
			Section16Mobile,
			Section17,
			Section18,
			FooterTbf
		},
		methods: {
			
		}
	}
</script>